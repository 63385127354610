@import '_colors';

$theme-colors: (
    'primary': map-get($colors, 'primary'),
    'secondary': map-get($colors, 'secondary'),
);

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

@import '_bs-icon-fixes';

.btn {
    box-shadow: 0 .125rem 0 -.25rem var(--bs-btn-bg);
    &:hover,
    &:focus,
    &:focus-visible {
        box-shadow: 0 .125rem 1rem -.25rem var(--bs-btn-bg);
    }
}