@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Share+Tech+Mono&family=Teko:wght@300..700&display=swap');

body {
	--bs-min-font-size: 0.875rem;
	--bs-max-font-size: 1.25rem;
	--bs-body-font-size: clamp(var(--bs-min-font-size), 2.75vmin, var(--bs-max-font-size));
	--btn-font-weight: 500;

	font-family: 'Open Sans', sans-serif;
	background-color: var(--color-dark);
	color: var(--color-light);
}

::selection {
    background-color: var(--primary);
    color: var(--color-light);
    text-shadow: 0 0 0.25em var(--color-dark),
                 0 0 0.125em var(--color-dark);
}

h1,
h2,
code,
h3,
h4,
h5,
h6,
.btn,
a {
	font-family: 'Share Tech Mono', sans-serif;
	letter-spacing: -0.06em;
}

:root {
	@for $i from 1 through 6 {
		--#{'h' + $i + '-min-font-size'}: 0.33rem + (#{8 - $i} * 0.33rem);
		--#{'h' + $i + '-max-font-size'}: .5rem + (#{8 - $i} * 0.4rem);
		--#{'h' + $i + '-variation'}: calc(4vmin + (0.5vmin * #{8 - $i}));
	}
}

@for $i from 1 through 6 {
    h#{$i} {
        font-size: clamp(var(--#{'h' + $i + '-min-font-size'}), var(--#{'h' + $i + '-variation'}), var(--#{'h' + $i + '-max-font-size'}));
        font-weight: calc(900 - (($i - 1) * 100));
		margin-bottom: calc(.25rem + 0.25em);
        color: var(--color-light);

        // @if $i <= 4 and $i > 2 {
        //     color: saturate(lighten(color('secondary'), (($i - 2) * 10%)), 100%);
        // } @else if $i <= 2 {
        //     color: saturate(lighten(color('primary'), (($i - 1) * 10%)), 100%);
        // }
	}

}

ol,
ul {
    padding-inline-start: 0;
	> li {
        background: transparent;
        position: relative;
        margin-inline-start: 2em;
        margin-bottom: 1em;

        &:after {
            pointer-events: none;
            content: '';
            position: absolute;
            display: block;
            inset: -.75em -.75em -.75em -2.75em;
            border-radius: 1.5rem;
            background: currentColor;
            opacity: 0;
            transition: opacity 0.15s ease-in-out, background 0.15s, color 0.15s;
        }

		&::marker {
            font-size: .9em;
            line-height: 1;
            margin-left: 1em;
			transition: opacity 0.15s ease-in-out, font-weight 0.15s ease-in-out;
            font-weight: 600;
		}

        &.no-marker {
            list-style-type: none;
            margin-inline-start: 0;
            &::marker {
                display: none;
            }
            &:after {
                inset: 0 0 0 0;
            }
        }

		&:hover {

            &:after {
                opacity: 0.04;
            }
			&::marker {
                font-weight: 800;
			}
		}

		@for $i from 1 through 6 {
			&:has(h#{$i}:first-child)::marker {
				font-size: clamp(var(--#{'h' + $i + '-min-font-size'}), var(--#{'h' + $i + '-variation'}), var(--#{'h' + $i + '-max-font-size'}));
			}
		}
	}
}

a:not(.btn) {
	// Links not in a p tag
	font-size: calc(var(--bs-body-font-size) + 0.25rem);
	line-height: 1.1;
	color: var(--color-light);

	text-decoration: underline;

	text-decoration-thickness: 0.1em;
	text-underline-offset: 0.2em;

	transition: text-shadow 0.15s ease-in-out;

	p & {
		// Links inside a p tag
		font-weight: bold;
		letter-spacing: -0.033em;
		font-family: unset;
		font-size: unset;
	}

    svg {
        filter: brightness(1) drop-shadow(0 0 0 var(--primary));
        transition: filter 0.15s;
    }

    &.active {
		text-shadow: 0 0 0.25em var(--primary),
                     0 0 2px var(--primary),
                     0 0 1px var(--primary);

        svg {
            filter: brightness(1.25) drop-shadow(0 0 0.25em var(--primary));
        }
	}
	&:hover,
	&:focus {
		text-shadow: 0 0 0.45em var(--primary),
                     0 0 2px var(--primary),
                     0 0 1px var(--primary);

        svg {
            filter: brightness(1.25) drop-shadow(0 0 0.45em var(--primary));
        }
	}

    &:focus-visible {
        text-shadow: 0 0 0.125em var(--primary);
        outline: 0.125em solid currentColor;
        outline-offset: 0.125em;

        svg {
            filter: brightness(1.25) drop-shadow(0 0 0.25em var(--primary));
        }
    }


	// Adds external link icon to links that start with http
	&[href^='http']:not(:has(img)) {
		position: relative;
		padding-inline-start: 0.25rem;
		padding-inline-end: 0.25rem;
		&:after {
			--svg-mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke="currentColor" stroke-width=".5" stroke-linecap="round" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/><path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/></svg>');

			content: '';
			display: inline-block;
			position: absolute;
			aspect-ratio: 1;
			height: 0.45em;
			bottom: calc(100% - .1em);
			left: calc(100% - .1em);
			background: currentColor no-repeat center / contain;
			mask-image: var(--svg-mask-image);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: center;
			mask-clip: content-box;
			mask-origin: content-box;

            will-change: auto;

			transition: bottom 0.15s ease-in-out, left 0.15s ease-in-out;
		}

		&:hover,
		&:focus {
			&:after {
				bottom: calc(100% + 0.1em);
				left: calc(100% + 0.1em);
			}
		}
	}
}

button,
a,
p,
ol,
li,
ul {
	svg {
		display: inline;
	}
}
