$colors: (
	// Brand Colors
	primary: #3c69ef,
	secondary: #00a65e,
	tertiary: #ed0547,

	color-dark: #1a1a1a,
	color-light: #eaeaea,

	pure-white: #fff,
	gray-100: #f7fafc,
	gray-200: #edf2f7,
	gray-300: #e2e8f0,
	gray-400: #cbd5e0,
	gray-500: #a0aec0,
	gray-600: #718096,
	gray-700: #4a5568,
	gray-800: #2d3748,
	gray-900: #1a202c,
	pure-black: #000,

	github-white: #f0f6fc,
	bluesky-blue: #0085ff,
	linkedin-blue: #0a66c2,
	twitch-purple: #9147ff,
	youtube-red: #ff0000,
	reddit-orange: #ff4500,
    buymeacoffee-yellow: #FD0,
);

:root {
	// Loop through the colors and create CSS variables
	@each $color-name, $color-value in $colors {
		--#{"" + $color-name}: #{$color-value};
	}
}

@function color($color) {
	@return map-get($colors, $color);
}