form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.input-group {
    display: flex;
    gap: 1rem;
}

label {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    color: var(--gray-300);
    text-align: start;
    overflow: visible;
    position: relative;

    .label-text {
        display: block;
        position: relative;
        transform: translateY(100%);
        padding: 0 clamp(0.125rem, 1vmin, .5rem);
        z-index: 0;

        transition: transform 0.2s ease-in-out;
    }

    &:has([required]) {
        .label-text::after {
            content: " *";
            color: var(--tertiary);
            font-weight: 900;
        }
    }

    &.entry-present {
        .label-text {
            transform: translateY(0);
        }
    }

    input, textarea, select {
        flex: 1;
        display: block;
        padding: clamp(0.125rem, 1vmin, .5rem);
        border: 1.5px solid var(--gray-500);
        border-radius: 0.5rem;
        background-color: var(--gray-800);
        color: var(--pure-white);
        font-weight: 700;
        outline: none;
        box-shadow: 0 0 0 0 rgba(color(primary), $alpha: 0);

        transition: box-shadow .2s ease-in-out, border .2s ease-in-out;
        z-index: 1;

        &::placeholder {
            color: var(--gray-500);
        }

        &:focus {
            border-color: $primary;
            box-shadow: 0 0 0 5px rgba(color(primary), $alpha: 0.5);
        }

        &[required]:user-invalid {
            border-color: var(--tertiary);
            &:focus {
                box-shadow: 0 0 0 5px rgba(color(tertiary), $alpha: 0.5);
            }
        }
    }

    textarea {
        resize: none;
    }
}
