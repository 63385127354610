@import '_reset';
@import '_custom';

@import '_typography';
@import '_forms';

@import '_portfolio';

@import '_extras';

body {
	background-color: var(--gray-900);
}

::-webkit-scrollbar {
    display: none;
}

#App {
	// text-align: center;
	min-height: 100vh;
	max-width: 2400px;
	max-height: 100vh;
	margin: auto;
	display: grid;
	color: var(--pure-white);
	background-color: var(--gray-900);

	position: relative;

	grid: 100vh / 400px auto;

	overflow: visible;

	z-index: 0;


	--header-spacing: 2rem;
	--mobile-header-height: clamp(100px, 20vh, 200px);
	--mobile-footer-height: 100px;

	@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
		--header-spacing: 1rem;
		grid: var(--mobile-header-height) calc(100vh - var(--mobile-header-height) - var(--mobile-footer-height)) var(--mobile-footer-height) / 100vw;
	}

	@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
		--header-spacing: 0.5rem;
		--mobile-footer-height: 200px;
	}

	aside {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: var(--header-spacing);
		margin: var(--header-spacing);
		border-radius: var(--header-spacing);
		gap: 1rem;
		box-shadow: 0.5rem 0.5rem 1rem rgba($color: $black, $alpha: 0.5), -0.5rem -0.5rem 1rem rgba($color: $black, $alpha: 0.5) inset,
			-0.5rem -0.5rem 1rem rgba($color: $gray-800, $alpha: 0.5), 0.5rem 0.5rem 1rem rgba($color: $gray-800, $alpha: 0.5) inset;

		overflow: hidden scroll;

		z-index: 1;

		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			flex-direction: row;
			align-items: center;
		}

		.site-logo {
			width: max(210px, 75%);
			aspect-ratio: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			margin: 0 auto;

			@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
                flex: 1 0 max(90px, 25%);
				height: 100%;
			}
		}

		nav {
			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				gap: 0 .75rem;
				margin: 0;
				align-items: stretch;
                text-align: center;

				@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
					align-content: center;
					flex-direction: row;
					align-items: center;
                    text-align: start;
				}

				li {
					display: flex;
					gap: 1rem;

					a {
						flex: 1;
					}

                    a {
                        padding: clamp(0.25rem, 1.9vmin, 1rem);
                        text-decoration: none;
                        color: var(--gray-400);
                        font-weight: 500;
                        letter-spacing: -.125rem;

                        transition: color .2s, font-weight .2s ease-in-out, letter-spacing .2s ease-in-out;

                        &.subdued {
                            font-size: 1.125rem;
                            letter-spacing: -.0875rem;
                        }

                        &.active {
                            font-weight: 900;
                            letter-spacing: 0rem;
                            color: var(--pure-white);
                        }
                    }
				}
			}
		}
	}

	main {
		display: flex;
		flex-direction: column;
		justify-content: safe center;
		padding: 2rem;
		gap: 1rem;

		text-align: start;

		overflow: hidden scroll;

		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			text-align: center;
		}
	}

	footer {
		display: flex;
		justify-content: safe center;
		gap: 1rem;
		padding: 0 1rem;
	}

	#desktopFooter {
		flex-direction: column;
		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			display: none;
		}
	}

	#mobileFooter {
		display: none;
		padding: 2rem;
		box-shadow: 0 0 5px 0 rgba($color: $black, $alpha: 1);

		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			display: flex;
		}

		@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
			flex-direction: column;
			padding: 1rem;
		}
	}
}

#constructionHeaderLogo,
#footerLogo {
	user-select: none;
	-webkit-user-drag: none;
}
#constructionHeaderLogo {
	height: clamp(120px, 20vmin, 200px);
}

.svg-container {
    flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
    max-width: 100%;
    max-height: 100%;
	svg {
		height: 100%;
	}
}

a:has(svg),
.btn {
	width: max-content;
	display: flex;
	gap: 1ch;
	align-items: center;
	justify-content: center;
}

.btn {
    &:focus-visible,
    &:focus,
    &:hover {
        border-color: currentColor;
    }
}
